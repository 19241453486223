import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Recoil your new state management for React Application",
  "date": "2021-01-03T00:00:00.000Z"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Intro`}</h2>
    <p>{`Hey, who is looking for something new and light to manage your state within your React app. Let's check `}<a parentName="p" {...{
        "href": "https://recoiljs.org/"
      }}>{`Recoil`}</a>{` - a new experimental tool for managing state inside React app from the facebookexperimental team.`}</p>
    <p>{`My personal impression it's awesome for MVP, Demo, and pets projects. This technology steel under development and at the current moment has version 0.1.2, it's a one-point and another there is still not solid practices and approaches, for some complex situations.`}</p>
    <p>{`But for small apps, it's amazing mixing inside two approaches like hooks and context, without any overhelmed boilerplate code (salut lovely Redux).`}</p>
    <h2>{`How to setup`}</h2>
    <p>{`Assume you do all npm module installation, and go to coding (As a starter boilerplate I took CRA)`}</p>
    <p>{`And then import import { RecoilRoot } from "recoil"; and wrap our application root component with RecoilRoot provider`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// index.js

import React from "react";
import ReactDOM from "react-dom";
import { RecoilRoot } from "recoil";
import App from "./App";

const rootElement = document.getElementById("root");
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>,
  rootElement
);
`}</code></pre>
    <h2>{`How to use`}</h2>
    <p>{`Now we can use it, let's assume that we have some service with notes, and in one place of application we want to leave note, and then render it in another one.`}</p>
    <p>{`Creating base component NoteForm.js with textarea`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// NoteForm.js
import React from "react";

export default function NoteForm() {
  return (
    <form>
      <textarea onChange={(e) => null} />
    </form>
  );
}
`}</code></pre>
    <p>{`And now we need to store value from our textarea somewhere, for this, we will use atom from recoil, it's almost the same as simple useState, but keeps value on the root provider (like global state in redux).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// NoteForm.js

import React from "react";
import { atom, useRecoilState } from "recoil";

export const inputState = atom({
  key: "inputState", // unique ID (with respect to other atoms/selectors)
  default: "", // default value (aka initial value)
});

export default function NoteForm() {
  const [, setText] = useRecoilState(inputState);

  return (
    <form>
      <textarea onChange={(e) => setText(e.target.value)} />
    </form>
  );
}
`}</code></pre>
    <p>{`Now after typing we store our value inside atom and can use this atom anywhere in our application, let's visualize our Note and get access to our stored atom.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Note.js

import React from "react";
import { inputState } from "./NotesForm";
import { useRecoilValue } from "recoil";

export default function Note() {
  return <div>{useRecoilValue(inputState)}</div>;
}
`}</code></pre>
    <p>{`And add it to our App.js component`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// App.js
import React from "react";
import "./styles.css";
import NotesForm from "./NoteForm";
import Note from "./Note";

export default function App() {
  return (
    <div className="App">
      <NotesForm />
      <Note />
    </div>
  );
}
`}</code></pre>
    <h2>{`How to enjoy :)`}</h2>
    <p>{`The full code example is here:`}</p>
    <p><a parentName="p" {...{
        "href": "https://codesandbox.io/s/simple-recoil-notes-ry5sw?file=/src/NotesForm.js"
      }}>{`https://codesandbox.io/s/simple-recoil-notes-ry5sw?file=/src/NotesForm.js`}</a></p>
    <p>{`We wrote a simple app with simple state manipulation, but with help of Recoil, we have a powerful possibility to share and write state across different hierarchical structures without boilerplate code, and with simple, and predictable behavior.`}</p>
    <h3>{`Resources:`}</h3>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://recoiljs.org/"
        }}>{`https://recoiljs.org/`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.youtube.com/watch?v=_ISAA_Jt9kI&feature=emb_logo"
        }}>{`https://www.youtube.com/watch?v=`}{`_`}{`ISAA_Jt9kI&feature=emb_logo`}</a></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      